<template>
  <div class="box">
    <div class="whh1">
      <div class="title">全部课程</div>
      <div class="content">
        <div class="left">
          <div class="tablist">
            <span>行业分类</span>
            <ul>
              <li @click="qb" :class="class1 == '全部' ? 'active' : ''">
                全部
              </li>
              <li
                @click="qhclassify(item.id, item.title)"
                :class="class1 == item.title ? 'active' : ''"
                v-for="(item, index) in list.classify"
                :key="index"
                :style="item.title == '职业培训免费学' ? 'color:red' : ''"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
          <div class="tablist1">
            <div class="list">
              <!-- <div class="item ">全部</div> -->
              <div
                @click="tabqh(2)"
                :class="active == 2 ? 'item active1' : 'item'"
              >
                企业培训
              </div>
              <div
                @click="tabqh(1)"
                :class="active == 1 ? 'item active1' : 'item'"
              >
                技能补贴
              </div>
              <div
                @click="tabqh(3)"
                :class="active == 3 ? 'item active1' : 'item'"
              >
                个人兴趣
              </div>
              <!-- |
              <el-checkbox style="margin-left: 14px" v-model="checked"
                >普通课</el-checkbox
              >
              <el-checkbox v-model="checked">直播课</el-checkbox> -->
            </div>
          </div>
          <div class="productlist" v-if="list1.length > 0">
            <div
              class="item"
              v-for="item in list1"
              :key="item.id"
              @click="detail(item)"
            >
              <img :src="item.cover" alt="" />
              <div class="title1">
                {{ item.title }}
              </div>
              <div class="hour">共{{ item.class_hour }}课时</div>
              <div class="price">
                <span>
                  {{ item.price == "0.00" ? "免费" : `${item.price}元` }}
                </span>
                <span class="span">{{ item.total_people }}人最近报名</span>
              </div>
            </div>
          </div>
          <div
            v-else
            style="text-align: center; margin: 100px auto; font-size: 16px"
          >
            暂无课程
          </div>
          <el-pagination
            style="margin-left: 20px; margin-bottom: 76px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            background
            :page-size="10"
            layout="prev, pager, next,total,  jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="right">
          <div class="title1">推荐课程</div>
          <div v-for="(item, index) in list2" :key="item.id">
            <div class="item" v-if="index < 6">
              <img :src="item.cover" alt="" />
              <div class="title2">
                {{ item.title }}
              </div>
              <div class="item_1">
                <div class="item_11">
                  {{ item.price == 0 ? "免费" : `${item.price}元` }}
                </div>
                <!-- <div class="item_12">51RGB在线教育…</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="guesswhat">
        <div class="title3">猜你喜欢的</div>
        <div class="lists">
          <div class="item">
            <img src="../../assets/shouye_banner1.jpg" alt="" />
            <div class="title2">
              Ps教程/平面设计/品牌logo/VI设 计/海报设计/淘宝美工/ 【直播】
            </div>
            <div class="item_1">
              <div class="item_11">免费</div>
              <div class="item_12">51RGB在线教育…</div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/shouye_banner1.jpg" alt="" />
            <div class="title2">
              Ps教程/平面设计/品牌logo/VI设 计/海报设计/淘宝美工/ 【直播】
            </div>
            <div class="item_1">
              <div class="item_11">免费</div>
              <div class="item_12">51RGB在线教育…</div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/shouye_banner1.jpg" alt="" />
            <div class="title2">
              Ps教程/平面设计/品牌logo/VI设 计/海报设计/淘宝美工/ 【直播】
            </div>
            <div class="item_1">
              <div class="item_11">免费</div>
              <div class="item_12">51RGB在线教育…</div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/shouye_banner1.jpg" alt="" />
            <div class="title2">
              Ps教程/平面设计/品牌logo/VI设 计/海报设计/淘宝美工/ 【直播】
            </div>
            <div class="item_1">
              <div class="item_11">免费</div>
              <div class="item_12">51RGB在线教育…</div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/shouye_banner1.jpg" alt="" />
            <div class="title2">
              Ps教程/平面设计/品牌logo/VI设 计/海报设计/淘宝美工/ 【直播】
            </div>
            <div class="item_1">
              <div class="item_11">免费</div>
              <div class="item_12">51RGB在线教育…</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      total: 0,
      class1: "全部",
      list: [],
      list1: [],
      list2: [],
      active: 1,
      checked: true,
      currentPage4: 1,
      page: 1,
      id: "",
    };
  },
  methods: {
    ...mapActions([
      "getIndex",
      "getMoreList",
      "getProClass",
      "getphoneIndex_x",
      "getclassList_x",
    ]),
    handleSizeChange(val) {},
    //切换分类
    handleCurrentChange(val) {
      this.page = val;
      if (this.class1 == "全部") {
        this.getclassList_x({
          classify_ids: "", //分类id
          is_charge: this.active,
          page: this.page, //分页页码，数字类型
          pageSize: "10",
        }).then((res) => {
          if (res.successCode == 200) {
            this.list1 = res.responseBody.list;
            this.list2 = res.responseBody.list;
            this.total = res.responseBody.total;
          }
        });
      } else {
        this.getclassList_x({
          classify_id: this.id, //分类id
          is_charge: this.active,
          page: this.page, //分页页码，数字类型
          pageSize: "10",
        }).then((res) => {
          if (res.successCode == 200) {
            this.list1 = res.responseBody.list;
            this.total = res.responseBody.total;
          }
        });
      }
    },
    //切换二级分类
    tabqh(val) {
      this.page = 1;
      this.active = val;
      this.class1 = "全部";
      this.getphoneIndex_x({
        is_charge: this.active,
      }).then((res) => {
        if (res.successCode == 200) {
          this.list = res.responseBody;
          this.getclassList_x({
            classify_ids: "", //分类id
            is_charge: this.active,
            page: this.page, //分页页码，数字类型
            pageSize: "10",
          }).then((res) => {
            if (res.successCode == 200) {
              this.list1 = res.responseBody.list;
              this.list2 = res.responseBody.list;
              this.total = res.responseBody.total;
            }
          });
        }
      });
    },
    //查看全部分类
    qb() {
      this.class1 = "全部";
      this.page = 1;
      this.getclassList_x({
        classify_ids: "", //分类id
        is_charge: this.active,
        page: this.page, //分页页码，数字类型
        pageSize: "10",
      }).then((res) => {
        if (res.successCode == 200) {
          this.list1 = res.responseBody.list;
          this.total = res.responseBody.total;
        }
      });
    },
    // 查看二级分类商品
    qhclassify(id, class1) {
      this.page = 1;
      this.class1 = class1;
      this.id = id;
      this.getclassList_x({
        classify_id: id, //分类id
        is_charge: this.active,
        page: this.page, //分页页码，数字类型
        pageSize: "10",
      }).then((res) => {
        if (res.successCode == 200) {
          this.list1 = res.responseBody.list;
          this.total = res.responseBody.total;
        }
      });
    },
    // 查看商品详情
    detail(item) {
      this.$router.push({
        path: "/coursedetails",
        query: {
          id: item.id,
          type: this.class1,
        },
      });
      return;
      // if (this.active != 3) {
      //   this.$router.push({
      //     path: "/coursedetails",
      //     query: {
      //       id: item.id,
      //       type: this.class1,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/enterprisecurriculumdetails",
      //     query: {
      //       id: item.id,
      //       type: this.class1,
      //     },
      //   });
      // }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.query.type == 4) {
      this.active = 2;
    } else {
      this.id = this.$route.query.id;
      this.active = this.$route.query.type;
      this.class1 = this.$route.query.name;
    }
    // 分类列表
    this.getphoneIndex_x({
      is_charge: this.active,
    }).then((res) => {
      if (res.successCode == 200) {
        this.list = res.responseBody;
      }
    });
    if (this.$route.query.is_type == 1) {
      this.class1 = "全部";
      this.getclassList_x({
        classify_ids: "", //分类id
        is_charge: this.active,
        page: this.page, //分页页码，数字类型
        pageSize: "10",
      }).then((res) => {
        if (res.successCode == 200) {
          this.list1 = res.responseBody.list;
          this.list2 = res.responseBody.list;
          this.total = res.responseBody.total;
        }
      });
    }
    if (this.$route.query.is_type == 2) {
      this.getclassList_x({
        classify_id: this.$route.query.id, //分类id
        is_charge: this.active,
        page: this.page, //分页页码，数字类型
        pageSize: "10",
      }).then((res) => {
        if (res.successCode == 200) {
          this.list1 = res.responseBody.list;
          this.total = res.responseBody.total;
          this.getclassList_x({
            classify_ids: "", //分类id
            is_charge: this.active,
            page: this.page, //分页页码，数字类型
            pageSize: "10",
          }).then((res) => {
            if (res.successCode == 200) {
              this.list2 = res.responseBody.list;
            }
          });
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  background: url("../../assets/beijingtu.png");
  overflow: hidden;
}
.whh1 {
  width: 1260px;
  margin: 0 auto;
}
.title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6c6c6c;
  height: 50px;
  line-height: 50px;
}
.content {
  display: flex;
  justify-content: space-between;
  .left {
    width: 1000px;
    .tablist {
      display: flex;

      height: 50px;
      line-height: 50px;
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      span {
        margin-right: 17.5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6c6c6c;
      }
      ul {
        display: flex;
        li {
          &:hover {
            color: #137cfb;
          }
          cursor: pointer;
          margin: 0 17.5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        .active {
          font-weight: 600;
          color: #137cfb;
        }
      }
    }
    .tablist1 {
      padding: 15px;
      box-sizing: border-box;
      margin-top: 17px;
      height: 60px;
      line-height: 30px !important;
      background: #f3f3f3;
      .list {
        display: flex;
        .item {
          cursor: pointer;
          margin-right: 25px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6c6c6c;
        }
        .active1 {
          box-sizing: border-box;
          padding: 5px 25px;
          height: 30px;
          color: #fff;
          line-height: 19px !important;
          background: #137cfb;
        }
      }
    }
    .productlist {
      padding: 20px;
      box-sizing: border-box;
      margin-top: 41px;
      display: flex;
      flex-wrap: wrap;
      .item {
        cursor: pointer;
        margin-right: 26px;
        margin-bottom: 22px;
        width: 220px;
        height: 216px;
        &:nth-child(4n) {
          margin-right: 0px;
        }
        img {
          width: 220px;
          height: 124px;
        }
        .title1 {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .hour {
          margin: 6px 0 7px 0;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #9d9d9d;
          line-height: 17px;
        }
        .price {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ef6430;
          line-height: 20px;
          .span {
            margin-left: 9px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #9d9d9d;
            line-height: 17px;
          }
        }
      }
    }
  }
  .right {
    height: 100%;
    padding: 9px;
    box-sizing: border-box;
    width: 237px;
    background: #f7f8f9;
    .title1 {
      font-size: 16px;
      height: 50px;
      line-height: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c6c6c;
      border-bottom: 1px solid #e1e1e1;
    }
    .item {
      cursor: pointer;
      margin-top: 30px;
      img {
        width: 220px;
        height: 124px;
      }
      .title2 {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .item_1 {
        margin-top: 9px;
        display: flex;
        justify-content: space-between;
        .item_11 {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ef6430;
        }
        .item_12 {
          width: 100px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #9d9d9d;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
.guesswhat {
  margin-top: 20px;
  padding: 29px 21px;
  box-sizing: border-box;
  background: #f7f8f9;
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #535353;
  .lists {
    display: flex;

    .item {
      cursor: pointer;
      margin-right: 29px;
      margin-top: 30px;
      width: 220px;
      img {
        width: 220px;
        height: 124px;
      }
      .title2 {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .item_1 {
        margin-top: 9px;
        display: flex;
        justify-content: space-between;
        .item_11 {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ef6430;
        }
        .item_12 {
          width: 100px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #9d9d9d;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
</style>
